<template>
  <div id="app">
    <div class="hero-gradient-bg">
      <div class="spacer"></div>
      <div class="columns is-vcentered">
        <div class="column is-5">
          <p class="is-subtitle-banner is-secondary is-bold m-1">
            Build your <span class="is-primary">brand</span>
          </p>
          <p class="is-subtitle is-gray is-bold m-1">the right way</p>
          <p class="py-4 m-1">
            Are you a small business owner, or about to start a new venture?
            Brandeeq enables you to build your brand the right way, first time.
          </p>
          <div class="box mt-4">
            <p class="is-bold">
              Don't miss out on your chance to get a FREE brand audit by our CEO
              George Noon, a former Fortune 300 COO.
            </p>
            <div v-if="freeAuditEmailSend && freeAuditEmailSend !== 'loading'">
              <p class="mt-5 mb-2 is-bold is-secondary">
                <span class="is-primary">
                  <i class="mdi mdi-check-circle mr-1"></i>
                </span>
                Thank you! We will contact you shortly.
              </p>
            </div>
            <div v-if="freeAuditEmailSend && freeAuditEmailSend == 'loading'">
              <p class="mt-5 mb-2 is-bold is-secondary">Submitting...</p>
            </div>
            <div v-if="!freeAuditEmailSend">
              <p class="mt-5 mb-4">
                Enter your email address and let us contact you
              </p>
              <b-field>
                <form @submit.prevent="submitEmailFreeAudit" id="freeAuditForm">
                  <div class="columns is-mobile">
                    <div class="column">
                      <b-input
                        placeholder="Enter your email"
                        v-model="freeAuditUserEmail"
                        type="search"
                        icon="email"
                        expanded
                        required
                      >
                      </b-input>
                    </div>
                    <div class="column is-narrow pl-0">
                      <button
                        form="freeAuditForm"
                        type="submit"
                        class="button button-primary hero-cta-gradient-bg has-text-white"
                      >
                        Contact Me
                      </button>
                    </div>
                  </div>
                </form>
              </b-field>
            </div>
          </div>
        </div>
        <div class="column is-1"></div>
        <div class="column has-text-right py-5 mt-5">
          <img src="@/assets/img/home/Hero_image.svg" class="mt-5" />
        </div>
      </div>
      <div class="spacer-small"></div>
    </div>
    <div class="app-body">
      <div class="spacer"></div>
      <div class="columns">
        <div class="column has-text-centered">
          <p class="is-title is-primary is-bold">
            Brand Building <span class="is-secondary">with Brandeeq</span>
          </p>
          <p class="py-4">
            Access the right services, in the right order, to build your brand
          </p>
        </div>
      </div>
      <div class="spacer-small"></div>
      <div class="columns is-vcentered">
        <div class="column has-text-centered">
          <img
            v-if="explainerHover == 0"
            src="@/assets/img/home/explainer-1.svg"
          />
          <img
            v-if="explainerHover == 1"
            src="@/assets/img/home/explainer-2.svg"
          />
          <img
            v-if="explainerHover == 2"
            src="@/assets/img/home/explainer-3.svg"
          />
          <img
            v-if="explainerHover == 3"
            src="@/assets/img/home/explainer-4.svg"
          />
        </div>
        <div class="column is-1"></div>
        <div class="column p-5">
          <div class="columns is-mobile" @mouseover="setExplainerHover(0)">
            <div class="column is-narrow">
              <b-tag
                class="number-tag is-bold"
                size="is-large"
                :type="explainerHover == 0 ? 'is-info' : 'is-light-gray'"
                rounded
              >
                1
              </b-tag>
            </div>
            <div class="column">
              <p
                class="is-subtitle-small is-bold"
                v-bind:class="{
                  'is-secondary': explainerHover == 0,
                  'is-gray': explainerHover != 0,
                }"
              >
                Start brand-building
              </p>
              <span v-if="explainerHover == 0" class="is-gray">
                Create a dedicated project area and get started
              </span>
            </div>
          </div>
          <div
            class="columns is-mobile is-vcentered"
            @mouseover="setExplainerHover(1)"
          >
            <div class="column is-narrow">
              <b-tag
                class="number-tag is-bold"
                size="is-large"
                :type="explainerHover == 1 ? 'is-info' : 'is-light-gray'"
                rounded
              >
                2
              </b-tag>
            </div>
            <div class="column">
              <p
                class="is-subtitle-small is-bold"
                v-bind:class="{
                  'is-secondary': explainerHover == 1,
                  'is-gray': explainerHover != 1,
                }"
              >
                Create a brief
              </p>
              <span v-if="explainerHover == 1" class="is-gray">
                We ask you the right questions, to create a great brief
              </span>
            </div>
          </div>
          <div
            class="columns is-mobile is-vcentered"
            @mouseover="setExplainerHover(2)"
          >
            <div class="column is-narrow">
              <b-tag
                class="number-tag is-bold"
                size="is-large"
                :type="explainerHover == 2 ? 'is-info' : 'is-light-gray'"
                rounded
              >
                3
              </b-tag>
            </div>
            <div class="column">
              <p
                class="is-subtitle-small is-bold"
                v-bind:class="{
                  'is-secondary': explainerHover == 2,
                  'is-gray': explainerHover != 2,
                }"
              >
                Put your expert to work
              </p>
              <span v-if="explainerHover == 2" class="is-gray">
                A properly breifed professional is now working for you
              </span>
            </div>
          </div>
          <div
            class="columns is-mobile is-vcentered"
            @mouseover="setExplainerHover(3)"
          >
            <div class="column is-narrow">
              <b-tag
                class="number-tag is-bold"
                size="is-large"
                :type="explainerHover == 3 ? 'is-info' : 'is-light-gray'"
                rounded
              >
                4
              </b-tag>
            </div>
            <div class="column">
              <p
                class="is-subtitle-small is-bold"
                v-bind:class="{
                  'is-secondary': explainerHover == 3,
                  'is-gray': explainerHover != 3,
                }"
              >
                Get quality options
              </p>
              <span v-if="explainerHover == 3" class="is-gray">
                Get what you need, uploaded to you in Brandeeq
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="spacer"></div>
      <p class="is-title is-secondary is-bold has-text-centered">
        See how others
        <span class="is-primary" style="word-wrap: break-word"
          >Turbocharged
        </span>
        their Brands
      </p>
      <p class="is-subtitle-small has-text-centered pb-5">
        Read Success Stories of Brandeeq Customers
      </p>
      <div class="columns mt-5">
        <div class="column is-2"></div>
        <div class="column">
          <div
            v-if="customerShowcase == 'tourly'"
            class="box p-5 tourly-gradient-bg"
          >
            <div class="p-10 box tourly-card-bg">
              <div class="columns is-vcentered">
                <div
                  class="column is-narrow text-vertical pl-0 is-hidden-mobile"
                >
                  BUILT WITH BRANDEEQ
                </div>
                <div class="column">
                  <div class="columns is-vcentered is-mobile">
                    <div class="column">
                      <img
                        src="@/assets/img/customers/tourly/logo_colored.svg"
                      />
                      <p class="has-text-info is-subtitle is-bold">
                        Redefining Tourism
                      </p>
                    </div>
                  </div>
                  <img
                    class="my-5 is-hidden-desktop"
                    src="@/assets/img/customers/tourly/tourly-card.png"
                  />
                  <p>
                    Learn how other small business owners and entrepreneurs
                    share how Brandeeq helped them build a brand.
                  </p>
                  <div class="py-4">
                    <p class="is-bold is-secondary">Atif Aziz</p>
                    <p class="is-small is-secondary">COO, Tourly</p>
                  </div>

                  <div
                    class="columns is-mobile is-vcentered has-pointer"
                    @click="toggleModal('tourly')"
                  >
                    <div class="column is-narrow pr-0">
                      <b-icon
                        icon="arrow-right-drop-circle"
                        type="is-info"
                      ></b-icon>
                    </div>
                    <div class="column pl-2">Watch Video</div>
                  </div>
                </div>
                <div
                  class="column is-narrow is-hidden-mobile is-hidden-tablet-only"
                >
                  <div style="margin-right: -10vw">
                    <img
                      src="@/assets/img/customers/tourly/tourly-card.png"
                      style="width: 30vw"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="customerShowcase == 'iinix'"
            class="box p-5 iinix-gradient-bg"
          >
            <div class="p-10 box tourly-card-bg">
              <div class="columns is-vcentered">
                <div
                  class="column is-narrow text-vertical pl-0 is-hidden-mobile"
                >
                  BUILT WITH BRANDEEQ
                </div>
                <div class="column">
                  <div class="columns is-vcentered is-mobile">
                    <div class="column">
                      <img
                        src="@/assets/img/customers/iinix/logo.svg"
                        class="my-5"
                        width="120px"
                      />
                      <p class="has-text-info is-subtitle is-bold">
                        Rebranded to Perfection
                      </p>
                    </div>
                  </div>
                  <p>
                    Learn how other small business owners and entrepreneurs
                    share how Brandeeq helped them build a brand.
                  </p>
                  <div class="py-4">
                    <p class="is-bold is-secondary">Sajjad Zaidi</p>
                    <p class="is-small is-secondary">Founder, IINIX</p>
                  </div>

                  <div
                    class="columns is-mobile is-vcentered has-pointer"
                    @click="toggleModal('iinix')"
                  >
                    <div class="column is-narrow pr-0">
                      <b-icon
                        icon="arrow-right-drop-circle"
                        type="is-info"
                      ></b-icon>
                    </div>
                    <div class="column pl-2">Watch Video</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-2"></div>
      </div>

      <p class="has-text-centered mt-5 pt-5">
        <i class="mdi mdi-arrow-down"></i> View Case Studies
      </p>
      <router-link :to="{ name: 'CaseStudies' }">
        <div class="columns is-mobile is-hidden-mobile">
          <div class="column is-2 is-hidden-mobile"></div>
          <div class="column has-text-centered">
            <img src="@/assets/img/home/tourly.svg" width="90%" />
          </div>
          <div class="column has-text-centered is-hidden-mobile">
            <img src="@/assets/img/home/kite_kitchen.svg" width="90%" />
          </div>
          <div class="column has-text-centered">
            <img src="@/assets/img/home/gigtal.svg" width="90%" />
          </div>
          <div class="column has-text-centered">
            <img src="@/assets/img/home/iinix.svg" width="90%" />
          </div>
          <div class="column has-text-centered is-hidden-mobile">
            <img src="@/assets/img/home/breakfast_cart.svg" width="90%" />
          </div>
          <div class="column has-text-centered">
            <img src="@/assets/img/home/roads.svg" width="90%" />
          </div>
          <div class="column is-2 is-hidden-mobile"></div>
        </div>
      </router-link>

      <div class="spacer"></div>
      <div class="columns is-vcentered">
        <div class="column">
          <p class="is-title is-secondary is-bold">
            <span class="is-primary">Benefits</span> for You
          </p>
          <p class="py-4">Here's how the Brandeeq method benefits you:</p>
          <div class="columns is-vcentered is-mobile pt-4">
            <div class="column is-narrow pr-0">
              <b-icon icon="check-circle" type="is-info"></b-icon>
            </div>
            <div class="column pl-2">
              Access the right services in one place
            </div>
          </div>
          <div class="columns is-vcentered is-mobile">
            <div class="column is-narrow pr-0">
              <b-icon icon="check-circle" type="is-info"></b-icon>
            </div>
            <div class="column pl-2">
              A fail-safe method helps you brand-build the right way and removes
              the classic inefficiencies... and get it right the first time
            </div>
          </div>
          <div class="columns is-vcentered is-mobile">
            <div class="column is-narrow pr-0">
              <b-icon icon="check-circle" type="is-info"></b-icon>
            </div>
            <div class="column pl-2">
              Professional brand assets, uploaded to your project area
            </div>
          </div>
        </div>
        <div class="column is-1"></div>
        <div class="column">
          <img src="@/assets/img/home/benefit_illustration.svg" />
        </div>
      </div>
      <div class="spacer"></div>

      <div class="columns is-vcentered">
        <div
          class="column has-text-centered has-pointer"
          @click="toggleModal('about-company')"
        >
          <img src="@/assets/img/home/about_company_illustration.svg" />
        </div>
        <div class="column">
          <p class="is-title is-secondary is-bold">
            About our <span class="is-primary">Brand</span>
          </p>
          <p class="mt-4">
            We believe that a robust methodology offers a solid foundation for
            entrepreneurs and small business owners to build distinct and
            sustainable brands -- then protect them.
          </p>
          <p class="mt-4">
            In the early stages of a new venture, minutes and rupee are
            precious. Yet, we see limited resources being redirected to manage
            corrections, rework or restarting new or very young brands.
          </p>
          <p class="mt-4">
            Such situations can and should be avoided. It is possible to get it
            right, first time.
          </p>
          <p class="mt-4 mb-4">
            Brandeeq is here to help you to build your brand the right way, from
            the get-go.
          </p>
          <router-link :to="{ name: 'Contact' }">
            <b-button type="is-info mt-4">Contact Us</b-button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="spacer"></div>
    <div class="bundle-section-bg">
      <div class="bundle-section-child">
        <div class="columns is-vcentered py-5 my-5">
          <div class="column pl-5 is-hidden-mobile">
            <p class="title bold has-text-white">
              Bundles to Help You Choose Better
            </p>
          </div>
          <div class="column has-text-centered pl-5 is-hidden-tablet">
            <p class="title bold has-text-white">
              Bundles to Help You Choose Better
            </p>
          </div>
          <div class="column has-text-centered pl-5">
            <router-link :to="{ name: 'Login' }">
              <img
                class="hover"
                src="@/assets/img/home/bundle_section/tile-1.svg"
              />
            </router-link>
          </div>
          <div class="column has-text-centered pl-5">
            <router-link :to="{ name: 'Login' }">
              <img
                class="hover"
                src="@/assets/img/home/bundle_section/tile-2.svg"
              />
            </router-link>
          </div>
          <div class="column has-text-centered pl-5">
            <router-link :to="{ name: 'Login' }">
              <img
                class="hover"
                src="@/assets/img/home/bundle_section/tile-3.svg"
              />
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- loading images beforehand -->
    <img src="@/assets/img/home/explainer-1.svg" style="display: none" />
    <img src="@/assets/img/home/explainer-2.svg" style="display: none" />
    <img src="@/assets/img/home/explainer-3.svg" style="display: none" />
    <img src="@/assets/img/home/explainer-4.svg" style="display: none" />

    <!-- Modals Start -->
    <b-modal v-model="showTourlyModal" has-modal-card>
      <div class="modal-card m-0" style="width: auto">
        <section class="modal-card-body p-0 m-0">
          <div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/5tZGoqmHH1I"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div class="columns is-vcentered m-2">
            <div class="column is-narrow">
              <img
                class="mt-2"
                src="@/assets/img/customers/tourly/logo_colored.svg"
                width="64px"
              />
            </div>
            <div class="column">Customer Feedback</div>
          </div>
        </section>
      </div>
    </b-modal>
    <b-modal v-model="showIinixModal" has-modal-card>
      <div class="modal-card m-0" style="width: auto">
        <section class="modal-card-body p-0 m-0">
          <div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/FNfy0R0biAw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div class="columns is-vcentered m-2">
            <div class="column is-narrow">
              <img
                class="mt-1"
                src="@/assets/img/customers/iinix/logo.svg"
                width="64px"
              />
            </div>
            <div class="column">Customer Feedback</div>
          </div>
        </section>
      </div>
    </b-modal>
    <b-modal v-model="showAboutCompanyModal" has-modal-card>
      <div class="modal-card m-0" style="width: auto">
        <section class="modal-card-body p-0 m-0">
          <div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/SPhmf5Zcf6w"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </section>
      </div>
    </b-modal>
    <!-- Modals End -->
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      explainerHover: 1,
      showTourlyModal: false,
      showIinixModal: false,
      showAboutCompanyModal: false,
      freeAuditEmailSend: false,
      freeAuditUserEmail: undefined,
    };
  },
  computed: {
    customerShowcase() {
      const random_item = (items) => {
        return items[Math.floor(Math.random() * items.length)];
      };
      var items = ["iinix", "tourly"];
      return random_item(items);
    },
  },
  created() {
    setInterval(() => {
      this.explainerHover = (this.explainerHover + 1) % 4;
    }, 3000);
  },
  methods: {
    async submitEmailFreeAudit() {
      this.freeAuditEmailSend = "loading";
      const submitted = await this.$store.dispatch("submitContactForm", {
        fullName: this.freeAuditUserEmail,
        email: this.freeAuditUserEmail,
        message: `Free brand Audit requested by ${this.freeAuditUserEmail}`,
      });
      if (submitted) {
        this.freeAuditEmailSend = true;
      } else {
        this.freeAuditEmailSend = false;
      }
    },
    setExplainerHover(val) {
      this.explainerHover = val;
    },
    toggleModal(type) {
      if (type == "about-company") {
        this.showAboutCompanyModal = !this.showAboutCompanyModal;
      }
      if (type == "tourly") {
        this.showTourlyModal = !this.showTourlyModal;
      }
      if (type == "iinix") {
        this.showIinixModal = !this.showIinixModal;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.is-light-gray {
  color: rgb(188, 188, 188) !important;
}

.hero-gradient-bg {
  background: linear-gradient(360deg, #9c7de3, #ffffff, #ffffff);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  max-width: 1980px;
  padding: 0vh 10vw;
  margin: 0 auto;
}

.hero-cta-gradient-bg {
  background: linear-gradient(-45deg, #a72ae8, #7e2be9);
}

.bundle-section-bg {
  background: #672ae8;
  background: linear-gradient(
    90deg,
    rgba(103, 42, 232, 1) 0%,
    rgba(232, 42, 156, 1) 100%
  );
}

.bundle-section-child {
  max-width: 1980px;
  padding: 0vh 10vw;
  margin: 0 auto;
}

.promo-card-container {
  width: 240px;
  height: 240px;
}

.promo-card-container video {
  background: url("~@/assets/img/customers/tourly/bg-video-still.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 280px;
  height: 280px;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  border-radius: 8px;
  scale: 1.2;
  margin-top: -20px;
  margin-left: 50px;
}

/* Just styling the content of the div, the *magic* in the previous rules */
.promo-card-container .caption {
  z-index: 1;
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.text-vertical {
  writing-mode: vertical-lr;
  text-orientation: sideways-right;
  letter-spacing: 5px;
}

.tourly-gradient-bg {
  background: linear-gradient(-45deg, #ea5459, #f7ba2c, #ea5459);
  background-size: 400% 400%;
  animation: gradient 12s ease infinite;
  box-shadow: 30px 30px 60px #d3d3d3, -20px -20px 60px #ffffff;
}

.iinix-gradient-bg {
  background: linear-gradient(-45deg, #103783, #9bafd9, #103783);
  background-size: 400% 400%;
  animation: gradient 12s ease infinite;
  box-shadow: 30px 30px 60px #d3d3d3, -20px -20px 60px #ffffff;
}

.tourly-card-bg {
  background: linear-gradient(345deg, #f4f4f4, #ffffff);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
